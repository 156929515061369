var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "grid1",
            attrs: {
              title: "점검항목별 개선목록",
              tableId: "grid1",
              columnSetting: false,
              usePaging: false,
              filtering: false,
              hideBottom: true,
              columns: _vm.grid1.columns,
              data: _vm.grid1.data,
              merge: _vm.grid1.merge,
              gridHeight: _vm.gridheight,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                      ? _vm._l(
                          _vm.$comm.ibmTagItems(props.row),
                          function (item, index) {
                            return _c(
                              "q-chip",
                              {
                                key: index,
                                staticStyle: {
                                  "margin-bottom": "4px !important",
                                },
                                attrs: {
                                  color: item.color,
                                  clickable: true,
                                  "text-color": "white",
                                  outline: "",
                                  square: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(item, props.row)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(item.title) + " "),
                                _c("c-assess-tooltip", {
                                  attrs: {
                                    ibmTooltip: item.ibmTooltip,
                                    ibmClassCd: item.ibmClassCd,
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        )
                      : void 0,
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "gridImpr",
              attrs: {
                title: "항목외 개선 목록",
                columns: _vm.gridImpr.columns,
                data: _vm.gridImpr.data,
                gridHeight: _vm.gridheight2,
                usePaging: false,
                filtering: false,
                columnSetting: false,
              },
              on: { linkClick: _vm.linkClick2 },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.popupParam.sopFireFightingCheckId
                        ? _c("c-btn", {
                            attrs: {
                              label: "개선요청",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addImpr },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.popupParam.sopFireFightingCheckId
                        ? _c("c-btn", {
                            attrs: {
                              label: "즉시조치",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addiimImpr },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }